import {
    useState,
    useEffect,
    type ChangeEvent,
} from 'react'
import type { SyntheticEvent } from 'react'
import { useAtomValue } from 'jotai'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.js'
import type { Moment } from 'moment'

import type {
    SearchType,
    TimeHighlight,
} from '../types'
import { RESERVATION_MIN_HOURS, START_DATE } from '../constants'
import type { END_DATE } from '../constants'
import { searchSubmissionAtom } from '../state'

export const useGarageSearch = () => {

    const searchSubmission = useAtomValue(searchSubmissionAtom)

    const initialSearch: SearchType = {
        startDate: searchSubmission.startDate,
        startTime: searchSubmission.startTime,
        endDate: searchSubmission.endDate,
        endTime: searchSubmission.endTime,
        location: searchSubmission.location,
        focusedInput: START_DATE,
    }
    
    const [currentSearch, setCurrentSearch] = useState<SearchType>(initialSearch)

    // Animation trigger for time picker upon date selection
    const [timeHighlight, setTimeHighlight] = useState<TimeHighlight>({
        startTime: false,
        endTime: false,
    })

    useEffect(() => {
        const timer = setTimeout(() => {
            if (timeHighlight.startTime === true || timeHighlight.endTime === true) {
                setTimeHighlight({
                    startTime: false,
                    endTime: false,
                })
            }
        }, 400)
        return () => clearTimeout(timer)
    }, [timeHighlight.startTime, timeHighlight.endTime])

    const [mobileSearchDialog, setMobileSearchDialog] = useState<boolean>(false)
    const [desktopSearchDialog, setDesktopSearchDialog] = useState<boolean>(false)

    const handleToggleMobileSearchDialog = () => {
        setMobileSearchDialog(!mobileSearchDialog)
        handleSearchReset()
    }

    const handleToggleDesktopSearchDialog = () => {
        setDesktopSearchDialog(!desktopSearchDialog)
        handleSearchReset()
    }

    /**
     * Saves content of a textField into the state object
     */
    const handleChange = (
        event: ChangeEvent<HTMLInputElement> | null,
        name: string,
    ) => {
        const newValue = event?.target.value
        setCurrentSearch(prevState => (
            {
                ...prevState,
                [name]: newValue,
            }
        ))
    }
    
    /**
     * Saves content of a textField into the state object
     */
    const handleSelect = (
        _event: SyntheticEvent | null,
        newValue: string | null,
        name: string,
    ) => {
        setCurrentSearch(prevState => (
            {
                ...prevState,
                [name]: newValue,
            }
        ))
    }

    /**
     * Saves date into the state object
     */
    const handleDateChange = (
        {
            startDate,
            endDate,
        }: {
            startDate: Moment | null,
            endDate: Moment | null,
        },
        focusedInput: typeof START_DATE | typeof END_DATE,
    ) => {
        if (focusedInput === START_DATE) {
            setCurrentSearch(prevState => (
                {
                    ...prevState,
                    startDate,
                    endDate: null,
                }
            ))
            setTimeHighlight(prevState => (
                {
                    ...prevState,
                    startTime: true,
                }
            ))
        } else {
            setCurrentSearch(prevState => (
                {
                    ...prevState,
                    startDate,
                    endDate,
                }
            ))
            setTimeHighlight(prevState => (
                {
                    ...prevState,
                    endTime: true,
                }
            ))
        }
    }

    /**
     * Shifts focus between start and end date
     * @param {*} focusedInput
     */
    const handleFocusChange = (focusedInput: typeof START_DATE | typeof END_DATE) => {
        setCurrentSearch(prevState => (
            {
                ...prevState,
                // Force the focusedInput to always be truthy so that dates are always selectable
                focusedInput: !focusedInput ? START_DATE : focusedInput,
            }
        ))
    }

    /**
     * Resets the search
     */
    const handleSearchReset = () => {
        setCurrentSearch(initialSearch)
    }
    
    const formattedStartDate = currentSearch.startDate ? `${moment(currentSearch.startDate).format('YYYY-MM-DD')}T${currentSearch.startTime}` : null
    const formattedEndDate = currentSearch.endDate ? `${moment(currentSearch.endDate).format('YYYY-MM-DD')}T${currentSearch.endTime}` : null
    let reservationDays: number
    let reservationHours: number
    const differenceInHours = moment(formattedEndDate).startOf('hour').diff(moment(formattedStartDate).startOf('hour'), 'hours')
    if (differenceInHours > 24) {
        reservationDays = Math.floor(differenceInHours/24)
        reservationHours = differenceInHours%24
    } else {
        reservationDays = 0
        reservationHours = differenceInHours
    }

    const currentDateTime = moment()
    const currentCETDateTime = currentDateTime.clone().tz("Europe/Prague").subtract({hours: 1})
    const selectedCETDateTime = moment(`${currentSearch.startDate?.format('YYYY-MM-DD')} ${currentSearch.startTime}`)

    const reservationTooShort = reservationDays < 1 && reservationHours < RESERVATION_MIN_HOURS
    const reservationInThePast = selectedCETDateTime < currentCETDateTime

    return {
        currentSearch,
        handleChange,
        handleSelect,
        handleDateChange,
        handleFocusChange,
        mobileSearchDialog,
        setMobileSearchDialog,
        handleToggleMobileSearchDialog,
        desktopSearchDialog,
        setDesktopSearchDialog,
        handleToggleDesktopSearchDialog,
        handleSearchReset,
        reservationTooShort,
        reservationInThePast,
        reservationDays,
        reservationHours,
        selectedCETDateTime,
        currentCETDateTime,
        timeHighlight,
    }
}
