import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.js'
import 'moment/dist/locale/cs'
import 'moment/dist/locale/de'
import 'moment/dist/locale/pl'

import { getLanguage } from './language'
import { en } from './locales/en'
import { cs } from './locales/cs'
import { de } from './locales/de'
import { pl } from './locales/pl'


const initialLanguage = getLanguage()

// Set moment locale from i18n, moment locale used by react-dates
moment.locale(initialLanguage)
if (initialLanguage === 'cs') {
    moment.updateLocale('cs', {
        months : [
            "leden", "únor", "březen", "duben", "květen", "červen", "červenec",
            "srpen", "září", "říjen", "listopad", "prosinec"
        ],
        weekdaysMin : [
            "Ne", "Po", "Út", "St", "Čt", "Pá", "So"
        ]
    })
}
moment.tz.setDefault('Europe/Prague')

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        lng: initialLanguage,
        fallbackLng: ['cs', 'en'],
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: { ...en },
            cs: { ...cs },
            de: { ...de },
            pl: { ...pl },
        }
    })

export default i18n
