import { atom } from 'jotai'
import type { SearchSubmissionType } from './types'
import { getSearchParams } from './helpers'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.js'

const {
    paramLocation,
    paramBeginsAtDate,
    paramBeginsAtTime,
    paramEndsAtDate,
    paramEndsAtTime,
} = getSearchParams()

export const searchSubmissionAtom = atom<SearchSubmissionType>({
    startDate: moment(paramBeginsAtDate, ['DD.MM.YYYY', 'YYYY-MM-DD']),
    startTime: paramBeginsAtTime,
    endDate: moment(paramEndsAtDate, ['DD.MM.YYYY', 'YYYY-MM-DD']),
    endTime: paramEndsAtTime,
    location: paramLocation,
})
