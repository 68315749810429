import type { cs } from "./cs"

export const en: typeof cs = {
    translation: {
        general: {
            bookNow: 'Book now',
            bookNowFor: 'Book now for',
            czk: 'CZK',
            occupied: 'Occupied',
            occupiedShort: 'Full',
            min: 'Min.',
            hours: 'hours',
            hoursShortcut: 'h',
            list: 'List',
            map: 'Map',
            filters: 'Filters',
            hide: 'Hide',
            garagesFound_one: '<strong>{{count}} garage</strong> found',
            garagesFound_few: '<strong>{{count}} garages</strong> found',
            garagesFound_many: '<strong>{{count}} garages</strong> found',
            garagesFound_other: '<strong>{{count}} garages</strong> found',
            garagesAvailableLater_one: 'Seems like a lot of garages are fully booked at this time. Set the reservation start time to <strong>18:00</strong> and there will be <strong>{{count}} more garage available</strong>.',
            garagesAvailableLater_few: 'Seems like a lot of garages are fully booked at this time. Set the reservation start time to <strong>18:00</strong> and there will be <strong>{{count}} more garages available</strong>.',
            garagesAvailableLater_many: 'Seems like a lot of garages are fully booked at this time. Set the reservation start time to <strong>18:00</strong> and there will be <strong>{{count}} more garages available</strong>.',
            garagesAvailableLater_other: 'Seems like a lot of garages are fully booked at this time. Set the reservation start time to <strong>18:00</strong> and there will be <strong>{{count}} more garages available</strong>.',
            moveToEighteen: 'Set start time to 18:00',
            close: 'Close',
            availableFromEighteen: 'Available from 18:00',
            fromEighteen: 'From 18:00',
            lastPlacesAvailable: 'Last places',
            boolean: {
                true: 'Yes',
                false: 'No'
            },
            phoneInput: {
                searchPlaceholder: "Select the country code",
            }
        },
        filters: {
            showAvailable: 'Show only available garages',
            yesShow: 'Yes',
            priceRange: 'Price range',
            carHeight: 'Maximum car height',
            sortBy: 'Sort by',
            distance: 'Distance',
            price: 'Price',
            remove: 'Remove filters',
            reset: 'Reset filters',
            showResults: 'Show results',
            noResultsMatch: 'No garages match these filters.',
        },
        search: {
            reset: 'Reset',
            edit: 'Edit',
            search: 'Search',
            where: 'Where do you want to park?',
            wherePlaceholder: 'City, Street, Place...',
            whereEmpty: 'No suggestions',
            start: 'Reservation from',
            end: 'Reservation until',
            reservationStart: 'Reservation from',
            reservationEnd: 'Reservation until',
            reservationLength: 'Reservation length',
            reservationExtendedLength: 'Extended reservation length',
            reservationDate: 'Reservation date',
            days_one: ' {{count}} day',
            days_few: ' {{count}} days',
            days_many: ' {{count}} days',
            days_other: ' {{count}} days',
            hours_one: ' {{count}} hour',
            hours_few: ' {{count}} hours',
            hours_many: ' {{count}} hours',
            hours_other: ' {{count}} hours',
            minutes_one: ' {{count}} minute',
            minutes_few: ' {{count}} minutes',
            minutes_many: ' {{count}} minutes',
            minutes_other: ' {{count}} minutes',
            selectStart: 'Please use the calendar to select reservation start date',
            selectEnd: 'Please use the calendar to select reservation end date',
            startInThePast: 'Your reservation starts in the past!',
            reservationTooShort: 'A reservation must be at least 6 hours long.',
            defaultLocation: 'Parking in Czech Republic',
            selectExtensionStart: 'Select new reservation START DATE in the calendar (or select current start date to keep it).',
            selectExtensionEnd: 'Select new reservation END DATE in the calendar (or select current end date to keep it).',
            extensionStartsTooLate: 'Extended reservation cannot start later than original reservation.',
            extensionEndsTooEarly: 'Extended reservation cannot end sooner than original reservation.',
            extensionIntoThePast: 'You are trying to extend the reservation into the past.',
            checkAvailability: 'Check availability',
            sendExtensionRequest: 'Send extension request',
            return: 'Return',
            extendReservation: 'Extend reservation',
            extensionPossible: 'It is possible to extend your reservation!',
            extensionPrice: 'Extension price: {{price}} CZK',
            payToContinue: 'To confirm the extension, please pay the price difference.',
            toPaymentCTA: 'To payment',
        },
        routes: {
            garages: 'garages',
            reservations: 'reservations',
            reservations_steps: {
                reservation_time: 'reservation-time',
            },
            users: 'users',
            signIn: 'sign-in',
            register: 'sign-up',
            signOut: 'sign-out',
        },
        reservations: {
            status: {
                booked: 'Booked',
                current: 'Current',
                elapsed: 'Elapsed',
                cancelled: 'Cancelled',
                allocated: 'Allocated',
            },
            general: {
                active: 'Current reservations',
                all: 'All reservations',
                noBooked: 'You don\'t have any current or future reservations.',
                noBookedCreate: 'Book now',
                remaining: 'Remaining:',
            },
            filters: {
                sortBy: 'Sort by',
                id: 'ID',
                startAt: 'Reservation start',
                status: 'Status filter',
                all: 'All',
                active: 'Current',
                elapsed: 'Elapsed',
                cancelled: 'Cancelled',
                allocated: 'Allocated',
            },
            table: {
                reservation: 'Reservation',
                garage: 'Garage',
                unit: 'Place',
                driver: 'Driver',
                licensePlate: 'Plate',
                phone: 'Phone',
                date: 'Date',
                price: 'Price',
                createdAt: 'Created at',
                garageAndUnit: 'Garage and place',
                from: 'From',
                to: 'To',
                phoneNumbers: 'Phone numbers',
                cancelReservation: 'Cancel',
                editDetails: 'Edit Plate/Phone',
                bookNextDay: 'Repeat booking for next day',
                bookTomorrow: 'Book from tomorrow',
                bookAgain: 'Book the same garage again',
                unpaidDifference: 'Please pay {{price}} {{currency}} to finalise the extension.',
                changeUnitApprove: 'To complete the parking space change, please confirm the garage rules.',
                confirm: 'Confirm',
                openGarage: 'Garage detail'
            },
            documents: {
                tickets: 'Tickets',
                invoices: 'Invoices',
                invoiceCorrections: 'Invoice corrections',
                creditNotes: 'Credit notes',
                other: 'Other documents',
                termsOfService: 'Terms of service',
                privacyPolicy: 'Privacy policy',
                garageOperatingTerms: 'Garage operating terms',
            },
            active: {
                open: 'Open',
                entrance: 'entrance',
                exit: 'exit',
                reception: 'reception',
                extend: 'Extend',
                payExtension: 'Pay difference',
                cancel: 'Cancel',
                confirm: 'Confirm',
                ending: 'Ending',
                starting: 'Starting',
                ended: 'Ended',
                cancelNotPossible: 'It is not possible to cancel your reservation:',
                cancelSuccessful: 'Your reservation has been successfully cancelled.',
            },
            edit: {
                save: 'Save',
                cancel: 'Cancel',
                phoneNumber1: 'Phone number 1',
                phoneNumber2: 'Phone number 2',
                licensePlate: 'License plate',
            },
            cancel: {
                confirmation: 'Do you really wish to cancel this reservation?',
                detail: 'Once the reservation is cancelled, it cannot be renewed.',
                cancel: 'Cancel reservation',
                keep: 'Keep it',
            },
            empty: {
                title: 'You have not made any reservation yet',
                text: 'Create a new reservation. You will then be able to see all your reservations here.'
            },
        },
        profile: {
            refetch: 'Try again',
            languageSelector: 'Select language',
            currencySelector: 'Select currency',
            signOut: 'Sign out',
            userProfile: {
                title: "User profile",
                email: "Email",
                userName: "Full name",
                phoneNumber1: "Phone n.1",
                phoneNumber1Tooltip: "Used for opening the garage",
                phoneNumber2: "Phone n.2",
                agreedToMarketing: "Consent to marketing communication",
                edit_agreedToMarketing: "Consent to marketing communication",
            },
            invoiceDetails: {
                title: "Billing details",
                edit_title: "Edit billing details",
                edit_save: "Edit",
                empty: "Not added yet",
                invoice_title: "Company name",
                invoice_street: "Address Line 1",
                invoice_city: "City",
                invoice_zip_code: "ZIP code",
                invoice_country: "Country",
                invoice_ic: "Company number",
                invoice_dic: "VAT number",
            },
            cars: {
                title: "Vehicles",
                edit_title: "Edit vehicle",
                edit_save: "Edit",
                add_title: "Add vehicle",
                add_confirm: "Add",
                name: "Name",
                numberPlate: "License plate",
                color: "Colour",
                add: "Add"
            }
        },
        success: {
            congratulations: {
                title: 'Successful reservation! Thank you.',
                imageAlt: 'Illustrative picture of the reservation',
                text: 'We have emailed a parking ticket with your reservation details to the provided email address. For your convenience, we have also sent you everything in a text message to the phone numbers you provided.The parking space must be vacated by the end of the reservation at the latest!',
                myReservationsLink: 'Continue to my reservations',
            },
            summary: { 
                title: 'Reservation detail',
            },
            reservationSummary: {
                totalPrice: 'Total price',           
                email: 'E-mail',
                name: 'Name',
                numberPlate: 'Plate',
                carColor: 'Color',
                floorWithNumber:'Floor / Parking space',
                phoneNumber:'Phone n.1',
                phoneNumber2:'Phone n.2',
            }
        },
        garage: {
            car_dimensions: {
                title: 'Maximum dimensions',
                height: 'Height',
                length: 'Length',
                width: 'Width',
            },
            showLess: 'Show less',
            showMore: 'Show more',
            mainBenefits: {
                first: 'Repeated entry and exit',
                second: 'Instructions in email and SMS',
                third: 'Easy cancellation and change of the reservation',
            },
            openOnMap: 'Navigate',
            showInstructions: "Important instructions",
            instructionsHeading: "Important instructions for arrival/departure",
            benefits: {
                title: 'Specifications',
                list: {
                    '10_minutes_form_city_center': '10 minutes from city centre',
                    '15_minutes_from_city_center': '15 minutes from city centre',
                    '5_minutes_form_city_center': '5 minutes from city centre ',
                    call_reception: 'Please call reception upon arrival.',
                    cctv: 'CCTV',
                    cctv_at_entrance: 'CCTV at entrance',
                    city_center: 'City centre',
                    historic_city_center: 'Historical city centre',
                    charging_station: 'Charging for EV',
                    licence_plate_validation: 'Entrance barrier opens with license plate recognition',
                    limited_vehicle_dimensions: 'Limited vehicle dimensions',
                    main_reception: 'You can gain access to the garage at the building\'s main reception in the lobby - only then can you enter the garage.',
                    nonstop_operation: 'Non-stop operation',
                    nonstop_reception: 'Non-stop reception',
                    nonstop_service: 'Non-stop operation',
                    number_plate_recognition: 'The camera reads your licence plate no. and opens the barrier',
                    open_gate_by_attendant: 'The gate will be opened by an attendant',
                    open_gate_by_phone: 'You open the gate with your phone',
                    parking_for_disabled: 'Kamerový systém u vjezdu',
                    security_system: 'Bezpečnostní systém',
                    subway_availability: 'Tramvaj v blízkosti',
                    tram_availability: 'Metro v blízkosti',
                    wc: 'WC',
                },
            },
            prohibitions: {
                title: 'Bans',
                list: {
                    lpg_cng_not_allowed: 'LPG und CNG nicht erlaubt',
                    electric_car_not_allowed: 'Parking of electric cars prohibited',
                    motorbikes_not_allowed: 'No motorcycle parking',
                },
            },
            garage_videos: {
                title: 'Videogallery',
            },
            confirmationModal: {
                maxDimensionsTitle: 'Please note',
                cancel: 'Cancel',
                complete: 'Book now',
            }
        },
        loginCrossroads: {
            login: "Log in",
            createAccount: "Register",
            skip: "Skip for now",
        },
        pwa: {
            search: 'Search',
            myReservations: 'My reservations',
            profile: 'Profile',
            myReservationsNoUser: 'You need to log in to manage your reservations and open your garage:',
            profileNoUser: 'To manage your profile, vehicles and invoicing you need to log in'
        },
        error: {
            heading: 'Oops! :(',
            title: 'An unexpected error has occurred',
            titleEmbedded: 'An unexpected error has occurred in this view - the rest of the application is very likely unaffected.',
            textEmbedded: 'Please try moving to a different tab, or restarting the app. You can also contact us through our website on our hotline.',
            moreInfo: 'Here are more details for the developers:'
        },
    },
}